.loader {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100dvh;
	width: 100dvw;
	position: fixed;
	top: 0;
	left: 0;
	background-color: rgba(0, 0, 0, 0.8);
	z-index: 100000000;
}
