.user-consent-modal {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: rgba(0, 0, 0, 0.5);
	backdrop-filter: blur(5px);

	button {
		border: none;
		outline: none;
		color: rgb(255, 255, 255);
		padding: 0.6em 2em;
		border-radius: 10px;
		width: fit-content;
		font-size: 14px;
		background-color: #2666be;

		&:hover {
			background-color: #1a4a9c;
			cursor: pointer;
		}

		&:disabled {
			background-color: #b3b3b3;
			cursor: not-allowed;
		}
	}

	.user-consent-container {
		width: calc(100% - 64px);
		max-width: 768px;
		height: calc(100% - 256px);
		display: flex;
		align-items: center;
		overflow-x: hidden;
		background-color: transparent;
		color: black;
		gap: 36px;

		.consent-state {
			border-radius: 12px;
			background-color: white;
			padding: 16px;
			min-width: calc(100% - 32px);
			max-height: calc(100% - 32px);
			display: flex;
			flex-direction: column;
			gap: 12px;

			.content {
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: space-between;
				gap: 24px;

				ol {
					align-self: flex-start;
					padding-left: 18px;

					li {
						margin-bottom: 4px;
					}
				}
			}

			.user-video-container {
				border-radius: 12px;
				overflow: hidden;
			}

			.face-detector {
				display: flex;
				flex-direction: column;
				align-items: flex-start;
				gap: 12px;
			}

			.audio-detector {
				display: flex;
				flex-direction: column;
				align-items: flex-start;
				gap: 12px;

				canvas {
					background-color: grey;
					border-radius: 12px;
				}
			}

			.screen-share-detector {
				display: flex;
				flex-direction: column;
				align-items: flex-start;
				gap: 12px;
				video {
					border-radius: 12px;
				}
			}

			.bottom-row {
				width: 100%;
				display: flex;
				align-items: flex-end;
				justify-content: space-between;
				gap: 12px;
			}

			.buttons {
				display: flex;
				align-items: center;
				justify-content: center;
				gap: 12px;
			}
		}
	}
}
