.react-switch-checkbox {
	height: 0;
	width: 0;
	visibility: hidden;
}

.react-switch-label {
	display: flex;
	align-items: center;
	justify-content: space-between;
	cursor: pointer;
	width: 100px;
	height: 50px;
	background: grey;
	border-radius: 100px;
	position: relative;
	transition: background-color 0.2s;
}

.react-switch-label .react-switch-button {
	content: "";
	position: absolute;
	top: 5px;
	left: 5px;
	width: 40px;
	height: 40px;
	border-radius: 45px;
	transition: 0.2s;
	box-shadow: 0 0 2px 0 rgba(10, 10, 10, 0.29);
}

.react-switch-button {
	display: flex;
	align-items: center;
	justify-content: center;
	color: #707070;
}

.react-switch-checkbox:checked
	+ .react-switch-label
	.react-switch-button {
	left: calc(100% - 5px);
	transform: translateX(-100%);
}

.react-switch-label:active .react-switch-button {
	width: 60px;
}
