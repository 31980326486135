.call-end-modal {
	display: flex;
	flex-direction: column;
	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	background-color: rgba(0, 0, 0, 0.5);
	align-items: center;
	justify-content: center;

	.content {
		display: flex;
		flex-direction: column;

		// align-items: center;
		justify-content: center;

		background-color: white;
		padding: 12px;
		border-radius: 16px;

		.heading {
			font-weight: bold;
			font-size: 24px;
			color: #333;
		}

		.description {
			font-size: 16px;
			color: #696a6b;
			margin-top: 12px;
		}

		.buttons {
			display: flex;
			align-items: center;
			justify-content: center;
			gap: 0;

			button {
				height: 32px;
				width: 62px;
				border-radius: 24px;
				border: none;

				&.yes {
					background-color: red;
					color: white;
					font-weight: 600;
				}

				&.no {
					background-color: #e0e0e0;
					color: #707070;
				}
			}
		}
	}
}
