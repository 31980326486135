@import "./components/index.scss";

body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI",
		"Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
		"Droid Sans", "Helvetica Neue", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background-color: #161b1d;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas,
		"Courier New", monospace;
}

* {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
}

.meeting-container {
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;
	height: 100dvh;
	width: 100dvw;

	.main {
		flex: 1;
		width: 100%;
		height: 100%;
		display: flex;

		.clock {
			position: fixed;
			bottom: 12px;
			left: 12px;
			display: flex;
			flex-direction: column;
			align-items: center;
			margin-top: 24px;
			color: gray;
			font-size: 12px;
			z-index: 1;
		}

		.transcript {
			position: fixed;
			bottom: 100px;
			left: 0;
			right: 0;
			justify-content: center;
			display: flex;
			width: 100%;
			align-items: flex-start;
			gap: 12px;
			color: white;
			z-index: 2;
			margin-left: -12px;
			max-width: 100dvw;
			padding: 12px;

			.transcript-role {
				color: gray;
				font-size: 12px;
				font-weight: 700;
			}

			.transcript-content {
				font-size: 14px;
				max-width: calc(100% - 40%);
			}
		}

		.question-container {
			display: flex;
			background-color: whitesmoke;
			padding: 0px 12px;
			height: fit-content;
			border-radius: 16px;
			z-index: 1000;
			box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.1);
			margin: 16px;
			margin-right: 4px;
		}
	}

	.user-video {
		z-index: 1000;
		padding: 12px;
		height: fit-content;
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
		cursor: pointer;

		video {
			border-radius: 16px;
			overflow: hidden;
			object-fit: cover;
			transition: all 0.2s;
			transform: scale(-1, 1);
		}

		canvas {
			transform: scale(-1, 1);
		}

		.avatar {
			position: absolute;
		}
	}

	.controls {
		position: fixed;
		bottom: 0;
		left: calc(50% - 500px);
		right: calc(50% - 500px);
		z-index: 100;
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 12px;
		gap: 2px;

		button {
			height: 48px;
			width: 62px;
			background-color: red;
			border-radius: 24px;
			border: none;
			margin: 0px 8px;
			cursor: pointer;
		}
	}

	.pre-call-screen {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		width: 100dvw;
		height: 100dvh;
		color: white;

		.company {
			font-size: 64px;
		}

		.user-details {
			margin-top: 40vh;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			gap: 12px;

			.user-name {
				margin-bottom: -10px;
			}
		}
	}
}

.call-info-buttons {
	position: fixed;
	bottom: 12px;
	right: 12px;
	z-index: 112;

	button {
		height: 44px;
		width: 44px;
		background-color: #e0e0e0;
		border-radius: 50%;
		border: none;
		color: #707070;
		cursor: pointer;
	}
}

.call-info-side-bar {
	position: fixed;
	bottom: 70px;
	right: 0;
	display: flex;
	flex-direction: column;
	z-index: 1000;
	display: flex;
	flex-direction: row;
	align-items: stretch;
	justify-content: flex-end;
	padding: 12px;

	.content {
		background-color: white;
		border-radius: 12px;
		padding: 12px;
		position: relative;
		font-weight: bold;

		label {
			// font-weight: 600;
			font-weight: lighter;
			color: #707070;
		}

		.group {
			padding: 8px 0px;
			// background-color: #f5f5f5;
		}
	}
}
